

.categoryCardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 kartice u jednom redu */
    gap: 40px; /* Razmak između kartica */
    padding: 30px;
    max-width: 1000px; /* Širina kontejnera */
    margin: 0 auto; /* Centriranje kartica */

}

.categoryCard {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.categoryCard:hover {
    transform: translateY(-5px); /* Efekt podizanja */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Dodaje sjenu */
}

.imageContainer {
    position: relative;
    padding-bottom: 56.25%; /* Omjer slike 16:9 */
    height: 0;
    overflow: hidden;
}

.imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;  /* Efekt na hover */
    border-radius: 10px;  /* Dodaj border-radius ovdje */
}


.categoryCard:hover img {
    transform: scale(1.1); /* Blago povećava sliku na hover */
}

.categoryCardTitle, .categoryCardSubtitle {
    position: absolute;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
}

.categoryCardTitle {
    top: 10px;
    left: 20px;
    font-size: 1.6rem;
}

.categoryCardSubtitle {
    bottom: 10px;
    left: 20px;
    font-size: 1.2rem;
}


.modalOverlay {
    background-color: rgba(0, 0, 0, 0.75); /* Overlay modala */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    padding: 20px;
    max-width: 500px; /* Širina modala */
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative; /* Pozicija X gumba */
}

.modalHeader {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centriranje */
    margin-bottom: 10px;
}

.modalTitle {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
    color: #333;
    padding-top: 10px;
}

.modalSubtitle {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin-top: 10px;
}

.closeButtonX {
    background: none;
    border: none;
    font-size: 1.5rem;  /* Veličina fonta za desktop */
    cursor: pointer;
    color: #eff2f5;
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 10px;
}


.closeButtonX:hover {
    color: #0056b3;
}

.modalInnerContent {
    max-height: 70vh; /* Ograničenje visine */
    overflow-y: auto;
    width: 100%;
    padding-right: 15px; /* Sprječavanje horizontalnog skrola */
}

.wordTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.wordTable thead {
    background-color: #28a745;
    color: white;
}

.wordTable th, .wordTable td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.wordTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

.closeButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: block;
    margin-left: auto;
}

.closeButton:hover {
    background-color: #0056b3;
}
@media (max-width: 1024px) {
    .categoryCardContainer {
        grid-template-columns: repeat(1, 1fr); /* 1 kartica po redu na manjim ekranima */
    }
}
/* Stilovi za mobilne uređaje do 768px */
@media (max-width: 768px) {
    .closeButtonX {
        font-size: 1.4rem;  /* Smanjena veličina za mobilne uređaje, ali povećano za lakše korištenje */
        color: white;       /* Bijela boja X-a */
        top: 10px;           /* Pozicija na vrhu */
        right: 10px;        /* Pozicija s desne strane */
        padding: 10;         /* Ukloni padding */
        width: 40px;        /* Automatska širina */
        height: 40px;       /* Automatska visina */
    }
}

/* Stilovi za manje uređaje do 480px */
@media (max-width: 480px) {
    .closeButtonX {
        font-size: 2rem;   /* Povećaj veličinu X za manje ekrane, ali da ostane vidljivo i jasno */
        color: white;      /* Bijela boja X-a */
        top: 5px;          /* Podesi poziciju na vrhu */
        right: 15px;       /* Podesi poziciju s desne strane */
        padding: 0;        /* Ukloni padding */
        width: 40px;       /* Automatska širina */
        height: auto;      /* Automatska visina */
    }
}
