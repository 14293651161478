/* Glavni container za Wordsexample */
.wordsexample-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
}

/* Naslov stranice */
.wordsexample-title {
    font-size: 28px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Stilovi za naslove kategorija */
.category-title {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
    cursor: pointer;
    color: #007BFF;
    transition: color 0.3s;
}

/* Hover efekt za kategorije */
.category-title:hover {
    color: #0056b3;
}

/* Lista riječi s ograničenom visinom i skrolanjem */
.word-list {
    max-height: 400px;  /* Ograničenje visine za skrolanje */
    overflow-y: auto;  /* Omogućeno vertikalno skrolanje */
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Tablica - osnovni stil */
.word-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 16px;
}

/* Zaglavlje tablice */
.word-table thead {
    background-color: #333;  /* Tamna pozadina */
    color: #fff;  /* Bijeli tekst */
}

.word-table th, .word-table td {
    border: 1px solid #ddd;  /* Okviri oko ćelija */
    padding: 10px;  /* Unutarnji razmak */
    text-align: left;  /* Tekst poravnat ulijevo */
}

/* Alternativne boje redova radi preglednosti */
.word-table tr:nth-child(even) {
    background-color: #f9f9f9;  /* Svjetlija pozadina za svaki drugi red */
}

/* Hover efekt za redove tablice */
.word-table tr:hover {
    background-color: #f1f1f1;
}

/* Stil za prazne rezultate */
.word-table td[colspan="3"] {
    text-align: center;
    color: #999;
}

/* Učitavanje */
p {
    font-style: italic;
    color: #666;
}

/* Odjeljak kategorije */
.category-section {
    margin-bottom: 20px;
}
