/* Kontejner za rječnik */
.dictionaryContainer {
    margin: 2rem auto;
    padding: 1rem;
    max-width: 500px;
    background-color: rgba(4, 7, 34, 0.9); /* Poluprozirna pozadina za bolji kontrast */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2; /* Ovaj z-index stavlja kontejner iznad pozadine */
}

/* Kontejner slike s omjerom 16:9 */
.imageContainer {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* Omjer 16:9 (9 / 16 = 0.5625) */
    height: 0;
    overflow: hidden;
    border-radius: 10px;
}

.dictionaryImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Zadržava omjer slike */
    border-radius: 10px;
}

/* Stil za naslov na slici */
.dictionaryTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2.9rem;
    font-weight: bold;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.6);
    z-index: 2; /* Naslov je iznad slike */
}

/* Tooltip stil */
.tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.8rem;
    top: -25px; /* Prikazuje se iznad riječi */
    left: 0;
    white-space: nowrap;
    z-index: 10;
    pointer-events: none; /* Tooltip ne blokira klik */
    opacity: 0.8;
}

/* Stil za pokazivač miša (ikona zvučnika) prilikom prelaska preko ćelija */
td {
    cursor: url('/public/images/speaker-icon.webp'), pointer; /* Pokazivač s ikonom zvučnika */
    position: relative;
}

/* Dodaj efekat hovera za bolji vizualni feedback */
td:hover {
    text-decoration: underline;
    color: #2980b9;
}

/* Dodaj hover efekt za redove */
.dictionaryRow:hover {
    background-color: #f2f2f2;
    transition: background-color 0.2s ease-in-out;
}

/* Stil za centriranje teksta u tablici */
th, td {
    padding: 0.4rem;
    text-align: center; /* Centriraj tekst u tablici */
    border-bottom: 1px solid #bdc3c7;
}

th {
    background-color: #3498db;
    color: white;
}

td {
    background-color: white;
}

/* Stil za filter */
.filterContainer {
    margin-top: 0.4rem;
    margin-bottom: 0.1rem;
    text-align: center;
}

.filterLabel {
    margin-right: 10px;
    font-weight: bold;
}

.filterSelect {
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #bdc3c7;
    width: 400px;
}

/* Stil za tablicu */
.tableWrapper {
    max-height: 500px; /* Maksimalna visina za tablicu */
    overflow-y: auto; /* Skrolanje unutar tablice */
    margin-top: 0.1rem; /* Razmak između tablice i filtra */
}

.dictionaryTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0.1rem;
}

/* Hover efekti za redove */
.dictionaryRow:hover {
    background-color: #f2f2f2;
}

/* Stilovi za mobilne uređaje */
@media (max-width: 768px) {
    .filterSelect {
        width: 300px; /* Smanji širinu padajućeg izbornika na tabletima */
    }

    .dictionaryTitle {
        font-size: 2.2rem; /* Smanji naslov na tabletima */
    }

    th, td {
        font-size: 0.9rem; /* Smanji veličinu fonta u tablici na tabletima */
    }
}

/* Za mobilne uređaje */
@media (max-width: 480px) {
    .filterSelect {
        width: 350px; /* Smanji širinu padajućeg izbornika na mobitelima */
    }

    .dictionaryTitle {
        font-size: 1.8rem; /* Još više smanji naslov na mobitelima */
    }

    th, td {
        font-size: 0.8rem; /* Smanji veličinu fonta u tablici na mobitelima */
    }

    td {
        cursor: pointer; /* Umjesto custom kursora, koristimo normalni pointer na mobilnim uređajima */
    }

    /* Ukloni hover efekte jer mobilni uređaji nemaju hover */
    td:hover {
        text-decoration: none;
        color: inherit;
    }
}
