/* Stilovi za cijeli container */
.examples-container {
    margin: 20px auto;
    max-width: 1200px;
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Naslov */
.examples-title {
    font-size: 2.5rem;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Stil za filter container */
.filter-container {
    margin-bottom: 20px;
    text-align: left;
}

.filter-label {
    font-size: 1rem;
    color: #555;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.filter-select {
    font-size: 1rem;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    display: block;
    width: 100%;
    max-width: 400px;
}

/* Tablica */
.examples-table {
    width: 100%; 
    border-collapse: collapse;
    margin-bottom: 30px;
    background-color: white;
}


/* Stilizacija zaglavlja tablice */
.table-header {
    padding: 12px 15px;
    font-size: 1rem;
    text-align: left;
    background-color: #54af4c !important; /* Svijetlo zelena boja za zaglavlje */
    color: white !important;
    
}

/* Redovi i ćelije */
.examples-row {
    border-bottom: 1px solid #ddd;
}

.examples-row:nth-child(even) {
    background-color: #f2f2f2;
}

.example-cell {
    padding: 12px 15px;
    font-size: 0.9rem;
    color: #333;
}

/* Stil za prazan podatak */
.no-examples {
    text-align: center;
    padding: 20px;
    color: #777;
}

/* Hover efekti */
.examples-row:hover {
    background-color: #e8f5e9; /* Lagano zelena na hover */
}

/* Responsivan dizajn za mobilne uređaje */
@media (max-width: 768px) {
    .examples-container {
        padding: 15px;
        max-width: 100%;
    }

    .examples-table {
        font-size: 0.8rem;
    }

    .table-header, .example-cell {
        padding: 10px 8px;
    }
}
