/* Kontejner za scenarije */
.scenarioCardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 4 kartice u jednom redu za velike ekrane */
    gap: 40px; /* Razmak između kartica */
    padding: 30px;
    max-width: 1200px;
    margin: 0 auto; /* Centriraj kartice */
}

/* Kartice */
.scenarioCard {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.scenarioCard:hover {
    transform: translateY(-5px); /* Podizanje na hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Dodaj sjenu */
}

.imageContainer {
    position: relative;
    padding-bottom: 56.25%; /* Omjer slike 16:9 */
    height: 0;
    overflow: hidden;
}

.imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;  /* Efekt na hover */
    border-radius: 10px;
}

.scenarioCard:hover img {
    transform: scale(1.1); /* Blago povećaj sliku na hover */
}

.scenarioCardTitle, .scenarioCardSubtitle {
    position: absolute;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
}

.scenarioCardTitle {
    top: 1px;
    left: 5px;
    font-size: 1.2rem;
}

.scenarioCardSubtitle {
    bottom: 1px;
    left: 5px;
    font-size: 1rem;
}

/* Stilovi za modal */
.modalOverlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
}

.modalHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.modalTitle {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
}

.modalSubtitle {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin-top: 10px;
}

.closeButtonX {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #007bff;
    position: absolute;
    top: 10px;
    right: 15px;
}

.modalInnerContent {
    max-height: 70vh;
    overflow-y: auto;
    width: 100%;
    padding-right: 15px;
}

.wordTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.wordTable thead {
    background-color: #28a745;
    color: white;
}

.wordTable th, .wordTable td {
    padding: 10px;
    border: 1px solid #ddd;
}

.wordTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

.closeButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    display: block;
    margin-left: auto;
}

.closeButton:hover {
    background-color: #0056b3;
}

/* Responzivnost */
@media (max-width: 1024px) {
    .scenarioCardContainer {
        grid-template-columns: repeat(2, 1fr); /* 2 kartice po redu na srednjim ekranima */
    }
}

@media (max-width: 768px) {
    .scenarioCardContainer {
        grid-template-columns: repeat(1, 1fr); /* 1 kartica po redu na manjim ekranima */
    }
}
