/* Resetiraj sve margine i padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
   
}

#root {
    height: 100%;
}

.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-image: url('/public/images/Homepage.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Arial', sans-serif;
    z-index: 2;
}

.homepage-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.199);
    z-index: 1;
}

/* Sloj za sadržaj */
.content-wrapper {
    background-color: rgba(0, 0, 0, 0.7); /* Tamna prozirna pozadina */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 800px;
    width: 100%;
    position: relative;
    z-index: 2;
    margin-top: 140px;  /* Dodaj razmak od vrha */
}


/* Stil za h1 naslov */
.homepage-title {
    font-size: 2.rem;  /* Postavi veličinu naslova */
    color: #ffffff;  /* Bijela boja teksta */
    margin-bottom: 2rem;  /* Razmak ispod naslova */
    text-align: center;  /* Centriraj tekst */
    font-weight: bold;  /* Podebljani naslov */
}

/* Stil za p element */
.homepage-paragraph {
    font-size: 1.2rem;  /* Veličina fonta za paragraf */
    color: #e0e0e0;  /* Lagano svijetla boja teksta (nije čisto bijela) */
    margin-bottom: 1.5rem;  /* Razmak ispod paragrafa */
    text-align: center;  /* Centriraj tekst */
    line-height: 1.6;  /* Povećaj razmak između redova */
}


label {
    font-weight: bold;
    margin-right: 10px;
    font-size: 1.1rem;
    color: #ffffff;
}

select {
    padding: 0.4rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #bdc3c7;
    margin-bottom: 1rem;
    width: 250px;
}

button {
    padding: 0.5rem 1.5rem;
    background-color: #3528a7;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

button:hover {
    background-color: #218838;
}

@media (max-width: 768px) {
    .homepage-container {
        padding-top: 100px;
    }

    h1 {
        font-size: 1.6rem;
    }

    p {
        font-size: 0.95rem;
    }

    select {
        width: 100%;
    }

    button {
        width: 60%;
    }
}

@media (max-width: 480px) {
    .homepage-container {
        padding-top: 10px;
    }

    h1 {
        font-size: 1.6rem;
    }

    p {
        font-size: 0.9rem;
    }

    select {
        width: 100%;
    }

    button {
        width: 60%;
    }
}
