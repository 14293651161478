/* Kontejner za cijelu stranicu kategorija */
.categoriesContainer {
    margin: 20px auto;
    max-width: 1200px;
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Naslovi */
.categoriesTitle {
    font-size: 2rem; /* Ispravljen font-size */
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Grid layout za tri kolone */
.categoriesGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

/* Stiliziranje svake sekcije tablice */
.categorySection {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
}

/* Naslovi unutar gridova */
.categoryTitle {
    font-size: 1.4rem;
    color: #555;
    margin-bottom: 10px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
    text-align: left;
}

/* Stiliziranje tablice */
.table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
}

/* Stiliziranje zaglavlja tablice */
.tableHead {
    background-color: #741f42;
    color: white;
}

.tableHeadCell {
    background-color: #120d63; /* Dodaj boju zaglavlju */
    color: white; /* Boja teksta */
    padding: 12px 10px;
    font-size: 1rem;
    text-align: left;
}

/* Stiliziranje redova tijela tablice */
.tableRow {
    border-bottom: 1px solid #ddd;
}

.tableRow:nth-child(even) {
    background-color: #f2f2f2;
}

.tableCell {
    padding: 12px 10px;
    font-size: 0.9rem;
    color: #333;
    text-align: left;
}

/* Responzivan dizajn za manje uređaje */
@media (max-width: 768px) {
    .categoriesGrid {
        grid-template-columns: 1fr; /* Na manjim ekranima prikazuje jedan stupac */
    }

    .categoriesContainer {
        padding: 15px;
        max-width: 100%;
    }

    .table {
        font-size: 0.8rem;
    }

    .tableHeadCell, .tableCell {
        padding: 10px 8px;
    }
}

/* Hover efekti */
.tableRow:hover {
    background-color: #e8f5e9;
}

/* Zaobljeni rubovi na tablici */
.table {
    border-radius: 8px;
    overflow: hidden;
}
