.specialized-dictionary-container {
  padding: 20px;
}

.dictionary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}

.word-card {
  background: #f9f9f9;
  border: 3px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Zadrži tekst na dnu kartice */
  overflow: hidden; /* Sakrij dio slike koji izlazi izvan granica kartice */
}

.word-card:hover {
  transform: scale(1.1);
}

.word-image {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9; /* Omjer slike 16:9 */
  object-fit: cover; /* Prilagodi sliku bez izobličenja */
}

h3 {
  margin: 20px 0;
  flex-grow: 1; /* Omogućava tekstu da se nalazi pri dnu */
}

p {
  margin-bottom: 10px; /* Ostavlja malo prostora ispod teksta */
}

.word-card p {
  margin: 5px 0; /* Dodaj malo razmaka između redaka */
  font-size: 1rem; /* Ako želiš podesiti veličinu fonta */
}

