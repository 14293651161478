/* Glavni container */
.generalLanguageContainer {
    padding: 50px;
    text-align: center;
}

/* Stil za naslov stranice */
.pageTitle {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 10px;
}

/* Stil za opis stranice */
.pageDescription {
    font-size: 1.2rem;
    color: #7f8c8d;
    margin-bottom: 30px;
}

/* Grid layout za kartice (2x3 raspored) */
.cardGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    justify-items: center;
    max-width: 80%;
    margin: 0 auto;
}

/* Stil za karticu */
.card {
    background-color: #f5f5f5;
    border: 2px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

/* Gornji dio kartice sa slikom */
.cardHeader {
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Slika unutar kartice */
.cardHeader img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Stil za naslov kartice */
.cardTitle {
    font-size: 1.2rem;
    color: #2c3e50;
    margin: 5px 0;
    text-align: center;
}

/* Efekt na hover */
.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Responzivnost za tablete */
@media (max-width: 1024px) {
    .cardGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    /* Smanji padding containera za manje uređaje */
    .generalLanguageContainer {
        padding: 30px;
    }

    /* Smanji veličinu slika i kartica na tabletima */
    .card {
        height: 220px;
        max-width: 350px;
    }

    .cardHeader {
        height: 150px;
    }

    .cardTitle {
        font-size: 1.1rem;
    }
    
    /* Smanji veličinu naslova i podnaslova */
    .pageTitle {
        font-size: 2rem; /* Smanji veličinu naslova */
    }

    .pageDescription {
        font-size: 1rem; /* Smanji veličinu podnaslova */
    }
}

/* Responzivnost za mobilne uređaje */
@media (max-width: 768px) {
    .cardGrid {
        grid-template-columns: 1fr;
        max-width: 100%;
        gap: 20px;
    }

    /* Smanji padding za mobilne uređaje */
    .generalLanguageContainer {
        padding: 20px;
    }

    /* Prilagodi visinu kartica i slika za mobilne uređaje */
    .card {
        height: 200px;
        max-width: 100%;
    }

    .cardHeader {
        height: 150px;
    }

    .cardTitle {
        font-size: 1rem;
    }
    
    /* Dodatno smanji veličinu naslova i podnaslova za mobilne uređaje */
    .pageTitle {
        font-size: 1.8rem; /* Još manja veličina naslova za mobitele */
    }

    .pageDescription {
        font-size: 0.9rem; /* Još manja veličina podnaslova za mobitele */
    }
}
