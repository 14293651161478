/* Glavni kontejner */
.verbTensesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 800px; /* Širina glavnog kontejnera */
    margin: 0 auto;
    height: calc(100vh - 50px); /* Fleksibilna visina */
    box-sizing: border-box;
}

/* Kontejner za tablicu glagola */
.verbTableContainer {
    width: 100%;
    flex-grow: 1; /* Fleksibilna visina */
    overflow-y: auto; /* Skrolanje unutar tablice */
    margin-bottom: 20px;
}

/* Tablica glagola */
.verbTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Fiksna širina stupaca */
}

.verbTable th, .verbTable td {
    padding: 10px;
    border: 2px solid #ddd;
    text-align: left;
    width: 33.33%; /* Jednaka širina stupaca */
}

.verbTable thead th {
    position: sticky;
    top: 0;
    background-color: #007bff;
    color: white;
    z-index: 1;
}

.verbTable tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

/* Aktivni red u tablici glagola */
.verbTable tbody tr.activeRow td {
    background-color: #3498db !important; /* Boja za aktivni redak u glagolima */
    font-weight: bold;
    font-size: 1.1rem;
    color: #ffffff !important; /* Svijetla boja teksta */
}

/* Sekcija za konjugacije */
.conjugationSection {
    width: 100%;
    flex-grow: 0; /* Prilagodljivo veličini sadržaja */
    background-color: #e6e5eba6;
    padding: 15px;
    border-radius: 5px;
    border: 3px solid #c3c6da;
    box-sizing: border-box;
}

/* Tablica konjugacija */
.conjugationTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.conjugationTable th, .conjugationTable td {
    padding: 10px;
    border: 2px solid #dddddd;
    width: 33.33%; /* Jednaka širina stupaca */
}

/* Glava konjugacija */
.conjugationTable thead {
    background-color: #2a0857; /* Boja glave konjugacija */
    color: white;
}

/* Postavi manju širinu za prvi stupac */
.conjugationTable th:first-child, 
.conjugationTable td:first-child {
    width: 15%; /* Postavi prvi stupac na manju širinu */
    background-color: #3498db; /* Ista boja pozadine kao zaglavlje */
    color: white; /* Boja teksta */
    font-weight: bold;
    font-size: 1rem;
    color: #ffffff !important; /* Svijetla boja teksta */

}

/* Svaki drugi red konjugacija */
.conjugationTable tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Aktivni redak konjugacija */
.conjugationTable tbody tr.activeConjugationRow td {
    background-color: #d4af37 !important; /* Zlatna boja za aktivni red u konjugacijama */
    font-weight: bold;
    color: #ffffff !important; /* Svijetla boja teksta */
}
