/* Container za scenarije */
.scenarios-container {
    margin: 20px auto;
    max-width: 1000px;
    font-family: 'Roboto', sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Naslovi */
.scenarios-title {
    font-size: 2.5rem;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Filter label */
.filter-container {
    margin-bottom: 20px;
}

.filter-label {
    font-size: 1.2rem;
    margin-right: 10px;
}

.filter-select {
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
}

/* Stiliziranje tablice */
.scenarios-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}

.scenarios-table-header th {
    padding: 12px 10px;
    font-size: 1rem;
    text-align: left;
    background-color: #db3490; /* Plava boja za zaglavlje */
    color: white;

}

.scenarios-table-body tr {
    border-bottom: 1px solid #ddd;
}

.scenarios-table-body tr:nth-child(even) {
    background-color: #f2f2f2; /* Naizmjenična boja redova */
}

.scenarios-table-body td {
    padding: 12px 10px;
    font-size: 0.9rem;
    color: #333;
}

/* Hover efekti */
.scenarios-table-body tr:hover {
    background-color: #d6eaf8; /* Lagano plava na hover */
}

.no-scenarios {
    text-align: center;
    padding: 20px;
    font-size: 1.2rem;
    color: #888;
}

/* Responsivan dizajn za mobilne uređaje */
@media (max-width: 768px) {
    .scenarios-container {
        padding: 15px;
        max-width: 100%;
    }

    .scenarios-table {
        font-size: 0.8rem;
    }

    .scenarios-table-header th, .scenarios-table-body td {
        padding: 10px 8px;
    }
}
