/* Overlay koji prekriva cijelu stranicu kada je modal otvoren */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Poluprozirna crna pozadina */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Visoki Z-index kako bi modal bio iznad svega */
}

/* Stil za sadržaj modala */
.modal-content {
    background-color: rgb(255, 255, 255); /* Bijela pozadina */
    border-radius: 8px;
    padding: 10px;
    max-width: 400px; /* Maksimalna širina modala */
    width: 100%; /* Širina 100% unutar raspoloživog prostora */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sjena za dojam dubine */
    position: relative; /* Potrebno za pozicioniranje gumba za zatvaranje */
    text-align: center; /* Cijeli modal centriran */
}

/* Stil za sliku unutar modala */
.modal-image {
    width: 100%;  /* Širina slike 100% unutar modala */
    height: auto;  /* Automatski postavi visinu */
    aspect-ratio: 16 / 9;  /* Omjer slike 16:9 */
    object-fit: cover;  /* Prilagodi sliku bez izobličenja */
    border-radius: 0;  /* Ukloni zaobljene rubove */
    margin-bottom: 20px;  /* Razmak ispod slike */
    display: block;  /* Prikaz slike kao bloka */
    margin-left: auto;  /* Centriraj sliku */
    margin-right: auto;  /* Centriraj sliku */
}


/* Stil za tablicu unutar modala */
.word-details-table {
    width: 100%;
    background-color: #f9f9f9; /* Svijetla pozadina za tablicu */
    border-collapse: collapse; /* Uklanja razmak između ćelija */
    margin-top: 10px;
}

/* Stil za ćelije tablice */
.word-details-table th, .word-details-table td {
    padding: 12px; /* Unutarnji razmak unutar ćelija */
    text-align: left; /* Poravnanje teksta lijevo */
}

/* Stil za zaglavlja tablice (Language, Translation) */
.word-details-table th {
    background-color: #4CAF50;  /* Zelena pozadina */
    color: white; /* Bijela boja fonta */
}

/* Stil za redove tablice */
.word-details-table td {
    background-color: white; /* Bijela pozadina za ćelije */
    color: #333; /* Tamniji tekst */
}

/* Stil za gumb za zatvaranje */
.close-modal {
    background-color: #ff4d4d; /* Crvena pozadina za gumb */
    color: white; /* Bijeli tekst */
    border: none; /* Uklanjanje rubova */
    padding: 8px 12px; /* Unutarnji razmak unutar gumba */
    font-size: 16px; /* Veličina fonta */
    border-radius: 5px; /* Zaokruženi rubovi */
    cursor: pointer; /* Kursor u obliku ruke kod prelaska miša */
    position: absolute; /* Pozicioniranje gumba unutar modala */
    top: 1px; /* Udaljenost od vrha */
    right: 10px; /* Udaljenost od desnog ruba */
}
