.jobs-container {
    padding: 60px;
    max-width: 1400px;
    margin: 0 auto; /* Centriranje na stranici */
    }


    .jobs-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Dva stupca */
        gap: 20px; /* Horizontalni i vertikalni razmak između kartica */
        border: 0px solid #007bff; /* Dodan border oko grida */
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2); /* Dodan shadow */
        border-radius: 15px; /* Zaobljeni rubovi */
        padding: 20px; /* Unutarnji razmak */
    }

.job-card {
    display: flex;
    align-items: center;
    background-color: #3b4483;
    border-radius: 50px;
    padding: 10px 20px;
    transition: transform 0.3s ease;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Širina kartice */
    margin-bottom: 50px; /* Vertikalni razmak između kartica */
}

.job-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.job-image-container {
    width: 150px; /* Veličina kruga */
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #3b4483;
    position: absolute;
    left: 0px; /* Podešavanje pozicije kruga lijevo */
    z-index: 1;
}

.job-image {
    width: 160%;
    height: 160%;
    object-fit: contain;
}

.job-info {
    flex-grow: 1;
    color: white;
    padding-left: 140px; /* Prostor između slike (kruga) i teksta */
}

.job-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: -10px;
}

.job-subtitle {
    font-size: 0.9rem;
    margin-bottom: 10px;
    color: #ddd;
}

.actions-cell {
    display: flex;
    gap: 10px;
}

.btn-action {
    padding: 8px 16px;
    font-size: 0.8rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.btn-action:hover {
    background-color: #0056b3;
}

/* Razmak između naslova kategorija i prve kartice */
.category-title {
    font-size: 1.8rem;
    margin-bottom: 30px; /* Prostor ispod naslova kategorije */
    color: #ffffff; /* Boja fonta */
    font-weight: bold; /* Povećaj debljinu fonta */
    text-shadow: 3px 3px 8px rgba(24, 52, 212, 0.979); /* Dodavanje sjene za bolju čitljivost */
    background-color: rgba(177, 18, 31, 0.815); /* Poluprozirna crna pozadina ispod naslova */
    padding: 5px; /* Prostor oko naslova kako bi se pozadina bolje vidjela */
    border-radius: 20px; /* Zaobljeni rubovi pozadine */
    text-align: center; /* Centriraj naslov */
}


.jobs-container {
    padding: 60px;
    max-width: 1200px;
    margin: 0 auto;
    margin-left: 400px;
}

/* Stilovi za manje ekrane, ali i dalje s dva stupca */
@media (max-width: 768px) { /* Mobilni uređaji i manji ekrani */
    .jobs-container {
        padding: 20px;
        margin-left: 0;
        max-width: 100%; /* Širina prilagođena ekranu */
    }

    .jobs-grid {
        grid-template-columns: repeat(2, 1fr); /* Dva stupca i na mobitelima */
        gap: 15px; /* Razmak između kartica */
    }

    .job-card {
        width: 100%; /* Kartice preuzimaju punu širinu unutar stupca */
        padding: 10px;
        margin-bottom: 15px; /* Razmak između kartica */
    }

    .job-image-container {
        width: 90px; /* Veličina kruga smanjena za mobilne uređaje */
        height: 90px;
        left: -45px; /* Podešavanje pozicije kruga lijevo */
    }

    .job-image {
        width: 120%;
        height: 120%;
    }

    .job-info {
        padding-left: 70px; /* Prostor između slike (kruga) i teksta */
    }

    .job-title {
        font-size: 1.1rem; /* Manji font za naslove */
    }

    .job-subtitle {
        font-size: 0.9rem; /* Manji podnaslov */
    }

    .btn-action {
        padding: 5px 10px; /* Smanji dugmad */
        font-size: 0.7rem; /* Smanji font za dugmad */
    }

    .category-title {
        font-size: 1.5rem; /* Smanji naslov kategorije na mobitelima */
        margin-bottom: 20px;
    }
}

/* Za još manje ekrane, poput vrlo malih mobitela */
@media (max-width: 480px) {
    .jobs-grid {
        grid-template-columns: repeat(1, 1fr); /* I dalje zadržavamo dva stupca */
        gap: 5px; /* Manji razmak između kartica */
    }

    .job-image-container {
        width: 100px; /* Još manji krug za slike */
        height: 100px;
        left: -25px;
    }

    .job-info {
        padding-left: 65px; /* Još manje prostora između slike i teksta */
    }

    .job-title {
        font-size: 1rem; /* Smanji naslov */
    }

    .job-subtitle {
        font-size: 0.7rem; /* Manji podnaslov */
    }

    .btn-action {
        padding: 2px 4px; /* Još manje dugmad */
        font-size: 0.6rem; /* Manji font za dugmad */
    }
}
