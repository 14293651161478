/* Stilizacija za gumbove u stupcu Actions */
.btn-action {
    background-color: #007bff;  /* Plava boja */
    color: white;
    padding: 8px 12px;
    text-decoration: none;
    border-radius: 5px;
    margin-right: 10px;  /* Razmak između gumbova */
    display: inline-block;
    font-size: 0.9rem;
    text-align: center;
    transition: background-color 0.3s ease;
}

.btn-action:hover {
    background-color: #0056b3;  /* Tamnija plava na hover */
}

/* Actions stupac, poravnavanje desno */
.actions-cell {
    text-align: right;
    white-space: nowrap;  /* Sprječava lomljenje gumbova */
}

/* Osiguraj da svi stupci budu jednake širine */
.jobs-table th, .jobs-table td {
    width: 25%;  /* Četiri stupca, pa svaki ima 25% */
}

/* Stilizacija za Jobs komponentu */
.jobs-container {
    margin: 20px auto;
    max-width: 700px;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.jobs-title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
}

.job-category {
    margin-bottom: 40px;
}

.category-title {
    font-size: 1.8rem;
    color: #555;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

/* Stiliziranje tablice */
.jobs-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: white;
}

.jobs-table-header {
    background-color: #af4c96;  /* Boja zaglavlja */
    color: white;
}

.jobs-table-header th {
    padding: 12px;
    text-align: left;
    font-size: 1.2rem;
}

.jobs-table-body tr {
    border-bottom: 1px solid #ddd;
}

.jobs-table-body tr:nth-child(even) {
    background-color: #f2f2f2;  /* Alternativna boja redova */
}

.job-cell {
    padding: 12px;
    font-size: 1rem;
    color: #333;
}

/* Hover efekt za redove */
.jobs-table-body tr:hover {
    background-color: #e8f5e9;
}

/* Filter container */
.filter-container {
    margin-bottom: 20px;
    text-align: left;
}

.filter-label {
    font-size: 1rem;
    margin-right: 10px;
}

.filter-select {
    padding: 8px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
}

/* Responsive design za manje ekrane */
@media (max-width: 768px) {
    .jobs-table th, .jobs-table td {
        font-size: 0.9rem;  /* Manji tekst za manje ekrane */
    }
    .btn-action {
        padding: 6px 10px;
        font-size: 0.8rem;  /* Manji gumbi */
    }
}