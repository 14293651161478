.mainPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-image: url('/public/images/Homepage.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Arial', sans-serif;
    z-index: 2;
}

.title { /* Stilovi specifični za h1 unutar MainPage komponente */
    color: #f9fafc;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.learningSection {
    background-color: #0a0a0a9a;
    padding: 20px;
    margin: 20px auto;
    width: 60%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.learningSection h2 {
    font-size: 2rem;
    color: #f9fafc;
    margin-bottom: 1rem;
}

.learningSection p {
    font-size: 1rem;
    color: #f1f4f7;
    margin-bottom: 1.5rem;
    text-align: center;
}

button {
    background-color: #3437db !important;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

button:hover {
    background-color: #45a049;
}

.backToHome {
    background-color: #3437db !important;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-top: 40px;
}

.backToHome:hover {
    background-color: #2980b9;
}

/* RESPONSIVNI DIZAJN */

/* Za tablete i manje uređaje */
@media (max-width: 768px) {
    .mainPageContainer {
        padding-top: 100px;
    }
    
    .learningSection {
        width: 80%;
    }

    .learningSection h2 {
        font-size: 1.75rem;
    }

    button {
        padding: 8px 16px;
        font-size: 14px;
    }
}

/* Za mobilne uređaje */
@media (max-width: 480px) {
    .mainPageContainer {
        padding-top: 80px;
    }

    .learningSection {
        width: 90%;
    }

    .learningSection h2 {
        font-size: 1.5rem;
    }

    .learningSection p {
        font-size: 0.9rem;
    }

    button {
        padding: 6px 12px;
        font-size: 14px;
    }
}
