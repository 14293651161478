.specializedScenariosContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.jobImageContainer {
    text-align: center;
    margin-bottom: 20px;
}

.jobImage {
    width: 600px;
    height: auto;
    object-fit: contain;
    border-radius: 0;
    border: 0px solid #007bff;
}

.scenariosTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.scenariosTable th,
.scenariosTable td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.scenariosTable th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.speakerText {
    cursor: pointer;
    position: relative;
}

.speakerIcon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    vertical-align: middle;
    display: inline-block;
}


.translateButton {
    padding: 5px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    width: 100%; /* Za male ekrane */
    text-align: center;
}

.translateButton:hover {
    background-color: #0056b3;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(63, 33, 197, 0.1);
    text-align: center; /* Centriraj tekst u modalu */
}

.modalImage {
    width: 400px;
    height: auto;
    object-fit: contain;
    border-radius: 0%;
    border: 0px solid #007bff;
    margin-bottom: 5px; /* Razmak ispod slike */
}

.modalTitle {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.closeButton {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.closeButton:hover {
    background-color: #ff3333;
}

/* ------------ Responsive styling for mobile and tablets ----------- */

/* Tablets */
@media (max-width: 1024px) {
    .jobImage {
        width: 400px; /* Manja slika na tabletima */
    }

    .modalImage {
        width: 300px; /* Manja slika unutar modala */
    }

    .scenariosTable th,
    .scenariosTable td {
        font-size: 16px; /* Smanji font za tablete */
    }

    .translateButton {
        padding: 5px 10px; /* Smanji padding gumba */
        width: auto; /* Uklanja 100% širine gumba na tabletima */
    }
}

/* Mobiteli */
@media (max-width: 768px) {
    .specializedScenariosContainer {
        padding: 10px; /* Smanji padding za manje uređaje */
    }

    .jobImage {
        width: 250px; /* Još manja slika za mobitele */
    }

    .modalImage {
        width: 200px; /* Manja slika unutar modala za mobitele */
    }

    .scenariosTable th,
    .scenariosTable td {
        font-size: 14px;
        padding: 10px; /* Smanji padding unutar tablice */
    }

    /* Gumb će biti blok unutar ćelije i centriran */
    .translateButton {
        padding: 5px 8px; 
        width: 100%; /* Gumb će zauzeti cijelu širinu na mobitelima */
    }

    /* Red HR & EN prijevoda */
    .scenariosTable td:nth-child(2) {
        display: flex; /* HR & EN će se prikazati u jednom redu */
        flex-direction: column;
        align-items: center; /* Centriraj tekst unutar ćelije */
        justify-content: center; /* Centriraj sadržaj vertikalno */
    }

    /* Modal će također biti manji */
    .modalContent {
        width: 100%;
        padding: 15px;
    }

    .modalTitle {
        font-size: 1rem; /* Manji naslov unutar modala */
    }

    .closeButton {
        margin-top: 15px; /* Smanji razmak iznad gumba */
    }
}

/* Vrlo mali mobiteli */
@media (max-width: 480px) {
    .jobImage {
        width: 200px; /* Najmanja verzija slike */
    }

    .modalImage {
        width: 150px; /* Još manja slika u modalu */
    }

    .translateButton {
        padding: 4px 6px; /* Još manji gumb za vrlo male uređaje */
    }

    /* Red HR & EN prijevoda */
    .scenariosTable td:nth-child(2) {
        display: flex; /* HR & EN će se prikazati u jednom redu */
        flex-direction: column;
        align-items: center; /* Centriraj tekst unutar ćelije */
        justify-content: center; /* Centriraj sadržaj vertikalno */
    }
}
